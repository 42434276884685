import I18n from 'i18n-js';
import get from 'lodash/get';

import { ErrorAction, getActionTypes, SuccessAction } from '../../uiCommon/redux/async';
import {
  getSimpleAction,
  getSimpleReducer,
  SimpleAction,
  SimpleReducer,
} from '../../uiCommon/redux/simple';

import {
  CREATE_AGENT,
  UPDATE_AGENT,
  INSTALL_LTI_CONFIG,
  LTIConfigResponse,
  CLEAR_CACHE_TOKEN,
  ClearCacheTokenResult,
} from './agent';
import { TEST_INST, TEST_SIS, TEST_CLEVER, TestResult, TEST_DATA_SYNC } from './client';
import { ABORT_JOBS } from './job';
import {
  RESET_COURSES,
  UPDATE_CATEGORY_MAPPING,
  UPDATE_ROLLOVER_DATES,
  DISABLE_SYNC_TO_SIS,
} from './ltiApiActions';
import { BULK_UPDATE_AGENTS_TEMPLATE, MigrationResults } from './templates';
import { UpdateCategoryMappingResponse } from './types';

export const SET_ALERT = 'SET_ALERT';

export type AlertState = {
  variant?: 'info' | 'success' | 'warning' | 'error';
  message?: string;
};

export const setAlert = (alert: AlertState): SimpleAction<AlertState> =>
  getSimpleAction(SET_ALERT, alert);

const errorTexts = {
  forbidden: I18n.t(
    'You do not have permission to access this resource. Please make sure you are authorized to view or interact with the requested content.',
  ),
  couldNotFetchInstanceId: I18n.t(
    'Could not fetch Canvas instance ID, failed to update schedules.',
  ),
  gpbJobAlreadyRunning: I18n.t(
    'There is already a grade sync job running, ' +
      'please wait for that job to complete before you schedule another grade sync job.',
  ),
  rosteringJobAlreadyRunning: I18n.t(
    'There is already a rostering job running, ' +
      'please wait for that job to complete before you schedule another rostering job.',
  ),
  resetIntegrationDataAlreadyRunning: I18n.t(
    'There is already a reset integration data job running, ' +
      'please wait for that job to complete before you schedule another one.',
  ),
  agentIsUnderMaintenance: I18n.t(
    'The requested action cannot be performed while the agent is under maintenance.',
  ),
};

export default (
  state: AlertState,
  action: SimpleAction<AlertState> | SuccessAction<unknown> | ErrorAction,
): AlertState => {
  const reducer: SimpleReducer<AlertState> = getSimpleReducer(SET_ALERT, {});

  switch (action.type) {
    case getActionTypes(CREATE_AGENT).success:
    case getActionTypes(UPDATE_AGENT).success:
      return {
        variant: 'success',
        message: I18n.t('Saved'),
      };
    case getActionTypes(INSTALL_LTI_CONFIG).success:
      const { data: installationData } = action as SuccessAction<LTIConfigResponse>;

      return {
        variant: 'success',
        message: installationData.message,
      };
    case getActionTypes(RESET_COURSES).success:
      return {
        variant: 'success',
        message: I18n.t('Reset integration data job has been started successfully!'),
      };
    case getActionTypes(TEST_INST).success:
    case getActionTypes(TEST_SIS).success:
    case getActionTypes(TEST_CLEVER).success:
    case getActionTypes(TEST_DATA_SYNC).success:
      const { data: testResult } = action as SuccessAction<TestResult>;

      return {
        variant: testResult.connected ? 'success' : 'error',
        message: testResult.connected ? I18n.t('Success') : testResult.message,
      };
    case getActionTypes(UPDATE_CATEGORY_MAPPING).success:
      const { data: groups } = action as SuccessAction<UpdateCategoryMappingResponse>;
      const failedAssignmentGroups = groups.filter(
        (assignmentGroup) => assignmentGroup.status === 'error',
      );

      if (failedAssignmentGroups.length > 0 && failedAssignmentGroups.length == groups.length) {
        return {
          variant: 'error',
          message: I18n.t('Failed to synchronize categories!'),
        };
      }
      if (failedAssignmentGroups.length > 0) {
        return {
          variant: 'warning',
          message: I18n.t('Failed to synchronize some categories!'),
        };
      }
      return {
        variant: 'success',
        message: I18n.t('Successfully synchronized categories!'),
      };
    case getActionTypes(ABORT_JOBS).success:
      return {
        variant: 'success',
        message: I18n.t('Successfully aborted job(s)'),
      };
    case getActionTypes(BULK_UPDATE_AGENTS_TEMPLATE).success:
      const { data: migrationResults } = action as SuccessAction<MigrationResults>;

      if (migrationResults.counts.failed) {
        return {
          variant: 'warning',
          message: I18n.t(
            'Failed to migrate %{failedCount} of the associated agents. Please check the inheritance log to see the details.',
            {
              failedCount: migrationResults.counts.failed,
            },
          ),
        };
      }

      return {
        variant: 'success',
        message: I18n.t('Successfully updated %{migratedCount} agents to %{targetTemplate}', {
          migratedCount: migrationResults.counts.migrated,
          targetTemplate: migrationResults.targetTemplate,
        }),
      };

    case getActionTypes(UPDATE_ROLLOVER_DATES).success:
      return {
        variant: 'success',
        message: I18n.t('Rollover dates were successfully updated.'),
      };

    case getActionTypes(DISABLE_SYNC_TO_SIS).success:
      return {
        variant: 'success',
        message: I18n.t('Disable sync to SIS job has been scheduled.'),
      };

    case getActionTypes(DISABLE_SYNC_TO_SIS).error: {
      const errorKey = get(action, 'error.errorKey');
      const message =
        errorKey === 'disableSyncToSisAlreadyRunning'
          ? I18n.t(
              'There is already a disable sync to SIS job running, ' +
                'please wait for that job to complete before you schedule another one.',
            )
          : I18n.t('Failed to create disable sync to sis job. Please contact support');

      return {
        variant: 'error',
        message,
      };
    }

    case getActionTypes(CLEAR_CACHE_TOKEN).success:
      const { data: cleanResult } = action as SuccessAction<ClearCacheTokenResult>;

      return {
        variant: cleanResult.cleaned ? 'success' : 'warning',
        message: cleanResult.cleaned ? I18n.t('Success') : I18n.t('No tokens in cache'),
      };
    default:
      if (action.type.endsWith('ERROR')) {
        const errorMessage = get(action, 'error.message');
        const errorKey = get(action, 'error.errorKey');

        const message = get(errorTexts, errorKey, errorMessage);

        if (message) {
          return {
            variant: 'error',
            message,
          };
        }
      }

      return reducer(state, action as SimpleAction<AlertState>);
  }
};
